<template>
    <main class="main-page"  id="">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid justify-content-between align-items-center">
                        <div  class="col " >
                            <div class=" text-2xl text-primary font-bold" >
                                Form P2h
                            </div>
                        </div>
                        <div  class="col-12 md:col-3 " >
                            <router-link :to="`/form_p2h/add`">
                                <Button label="Tambah Baru" icon="pi pi-plus" type="button" class="p-button w-full bg-primary "  />
                            </router-link>
                        </div>
                        <div  class="col-12 md:col-5 lg:col-4 " >
                            <span class="p-input-icon-left w-full">
                            <i class="pi pi-search" />
                            <InputText  placeholder="Search" class="w-full" :value="searchText" @input="debounce(() => { searchText = $event.target.value })"  />
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="flex align-items-center justify-content-around">
                            <div v-if="searchText" :class="filterTagClass">
                                Search
                                <Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}</Chip>
                            </div>
                        </div>
                        <div >
                            <template v-if="showBreadcrumbs && $route.query.tag && !isSubPage">
                                <Breadcrumb :home="{icon: 'pi pi-home', to: '/form_p2h'}" :model="pageBreadCrumb" />
                            </template>
                            <!-- Master Page Start -->
                            <!-- page records template -->
                            <div class="page-records" >
                                <div class="grid justify-content-start">
                                    <template  v-for="(data, index) of records" :key="`pagerecord-${index}`">
                                        <div class="col-12 md:col-4">
                                            <div class="card p-3 ">
                                                <div class="grid align-items-center">
                                                    <div  class="col">
                                                        <div class="font-bold">ID</div>
                                                        <div class="font-bold  text-400">
                                                            <router-link :to="`/form_p2h/view/${data.id}`">
                                                                {{ data.id }}
                                                            </router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr class="my-1" />
                                                <div class="grid align-items-center">
                                                    <div  class="col">
                                                        <div class="font-bold">Nama</div>
                                                        <div class="font-bold  text-400">{{ data.nama }}</div>
                                                    </div>
                                                </div>
                                                <hr class="my-1" />
                                                <div class="grid align-items-center">
                                                    <div  class="col">
                                                        <div class="font-bold">Employee Id</div>
                                                        <div class="font-bold  text-400">{{ data.employee_id }}</div>
                                                    </div>
                                                </div>
                                                <hr class="my-1" />
                                                <div class="grid align-items-center">
                                                    <div  class="col">
                                                        <div class="font-bold">Apakah kondisi kendaraan anda baik hari ini?</div>
                                                        <div class="font-bold  text-400">{{ data.q1 }}</div>
                                                    </div>
                                                </div>
                                                <hr class="my-1" />
                                                <div class="grid align-items-center">
                                                    <div  class="col">
                                                        <div class="font-bold">Apakah mesin kendaraan prima?</div>
                                                        <div class="font-bold  text-400">{{ data.q2 }}</div>
                                                    </div>
                                                </div>
                                                <hr class="my-1" />
                                                <div class="grid align-items-center">
                                                    <div  class="col">
                                                        <div class="font-bold">Apakah kelengkapan kendaran dalam kondisi baik?</div>
                                                        <div class="font-bold  text-400">{{ data.q3 }}</div>
                                                    </div>
                                                </div>
                                                <hr class="my-1" />
                                                <div class="grid align-items-center">
                                                    <div  class="col">
                                                        <div class="font-bold">Approval 1</div>
                                                        <div class="font-bold  text-400">{{ data.approval_1 }}</div>
                                                    </div>
                                                </div>
                                                <hr class="my-1" />
                                                <div class="grid align-items-center">
                                                    <div  class="col">
                                                        <div class="font-bold">Approval 2</div>
                                                        <div class="font-bold  text-400">{{ data.approval_2 }}</div>
                                                    </div>
                                                </div>
                                                <hr class="my-1" />
                                                <div class="grid align-items-center">
                                                    <div  class="col">
                                                        <div class="font-bold">Approval 3</div>
                                                        <div class="font-bold  text-400">{{ data.approval_3 }}</div>
                                                    </div>
                                                </div>
                                                <hr class="my-1" />
                                                <div class="flex gap-3 justify-content-end">
                                                    <Menubar class="p-0 " ref="actionMenu" :model="getActionMenuModel(data)" />
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <!-- page loading indicator -->
                            <template v-if="loading">
                            </template>
                            <!-- end of page loading indicator-->
                            <!-- Empty record -->
                            <template v-if="pageReady && !records.length">
                                <div class="p-3 my-3 text-500 text-lg font-medium text-center">
                                    Data Tidak Ditemukan
                                </div>
                            </template>
                            <!-- end of empty record-->
                            <!-- pagination component-->
                            <template v-if="showFooter">
                                <div class="">
                                    <div class="flex justify-content-between align-items-center">
                                        <div class="flex justify-content-center flex-grow-0">
                                            <div v-if="selectedItems.length" class="m-2">
                                                <Button @click="deleteItem(selectedItems)" icon="pi pi-trash" class="p-button-danger" title="Delete Selected" />
                                            </div>
                                        </div>
                                        <div v-if="paginate && totalPages > 1" class="flex-grow-1">
                                            <Paginator class="border-none bg-transparent py-3" :first="recordsPosition - 1" @page="(event)=>{pagination.page = event.page + 1}" :rows="pagination.limit" :totalRecords="totalRecords">
                                                <template #start>
                                                    <span class="text-sm text-gray-500 px-2">
                                                    Records <b>{{ recordsPosition }} of {{ totalRecords }}</b>
                                                    </span>
                                                </template>
                                                <template #end>
                                                </template>
                                            </Paginator>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- end of pagination component-->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { usePageStore } from 'src/store/page';
	import { useApp } from 'src/composables/app.js';
	import { useListPage } from 'src/composables/listpage.js';
	
	const props = defineProps({
		primaryKey : {
			type : String,
			default : 'id',
		},
		pageStoreKey: {
			type: String,
			default: 'FORM_P2H',
		},
		pageName: {
			type: String,
			default : 'form_p2h',
		},
		routeName: {
			type: String,
			default: 'form_p2hlist',
		},
		apiPath: {
			type: String,
			default: 'form_p2h/index',
		},
		paginate: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: true,
		},
		emptyRecordMsg: {
			type: String,
			default: "Data Tidak Ditemukan",
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete record",
		},
		msgBeforeDelete: {
			type: String,
			default: "Yakin ingin menghapus data ini?",
		},
		msgAfterDelete: {
			type: String,
			default: "Data berhasil dihapus",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 10,
		},
		mergeRecords: { // for infinite loading
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: 'desc', //desc or asc
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		filterTagClass: {
			type: String,
			default: 'surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-center',
		}
	});
	
	const app = useApp();
	
	const defaultStoreState = {
		filters: {
		},
		pagination: {
			page: props.page,
			limit: props.limit,
			sortBy: props.sortBy,
			sortType: props.sortType
		},
		searchText: props.search,
		primaryKey: props.primaryKey
	}
	const store = usePageStore(props.pageStoreKey,  defaultStoreState);
	
	// page hooks where logics resides
	const page = useListPage({ store, props });
	
	const {records, filters,  totalRecords,  selectedItems,  pagination,} = toRefs(store.state);
	const { pageReady, loading, searchText, } = toRefs(page.state);
	
	const {  pageBreadCrumb,   totalPages, recordsPosition, } = page.computedProps;
	
	const { load,    exportPage, debounce, clearSearch, onSort,  deleteItem,       } = page.methods;
	
	function getActionMenuModel(data){
		return [
		{
			label: "View",
			to: `/form_p2h/view/${data.id}`,
			icon: "pi pi-eye"
		},
		{
			label: "Edit",
			to: `/form_p2h/edit/${data.id}`,
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash"
		}
	]
	}
	
	onMounted(()=>{ 
		const pageTitle = "Form P2h";
		app.setPageTitle(props.routeName, pageTitle);
	});
</script>
<style scoped>
</style>
